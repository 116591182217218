import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import paginate from 'jw-paginate';
import { UntypedFormControl } from '@angular/forms';
import { UserCommonService } from '../../common/user-common.service';

@Component({
  selector: 'szz-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() items: Array<any> = [];
  @Output() changePage = new EventEmitter<any>(true);
  @Input() initialPage = 1;
  @Input() pageSize = 10;
  @Input() maxPages = 10;

  pages = [
    { key: '10', value: 10},
    { key: '25', value: 25},
    { key: '50', value: 50},
    { key: '100', value: 100},

  ]

  selectedFormItem: UntypedFormControl = new UntypedFormControl(50);

  pager: any = {};

  constructor(private userCommonService: UserCommonService){}

  ngOnInit() {
    // set page if items array isn't empty
    if (this.items && this.items.length) {
      this.setPage(this.initialPage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.pageSize = this.userCommonService.getPageSize();
    this.selectedFormItem.setValue(this.pageSize);
    this.initialPage = this.userCommonService.getCurrentPage();

    // reset page if items array has changed
    if (changes['items'].currentValue !== changes['items'].previousValue) {
      this.setPage(this.initialPage);
    }
  }

   setPage(page: number | any) {
      this.pageSize = this.selectedFormItem.value;

    // get new pager object for specified page
      this.pager = paginate(this.items.length, page, this.pageSize, this.maxPages);
      this.userCommonService.setCurrentPage(this.pager.currentPage);
      this.userCommonService.setPageSize(this.pageSize);

     // get new page of items from items array
     var pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);

    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }
}
