import { AbstractControl, ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class SzzValidators {
    static requiredValidator(errorMessage: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!!control.value) {
                return null;
            }
            return { required: { value: errorMessage } };
        };
    }

    static dateRangeRequiredValidator(errorMessage: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
          if(control.value && control.value[0] !== null && control.value && control.value[0] !== "" && control.value[1] !== null && control.value[1] !== "") {
              return null;
          }
          let dates = control.value as Array<Date>;
          if(dates && dates.length > 0 && dates[0] && dates[1]){
            return null;
          }
          return { required: { value: errorMessage } };
      };
  }

    static maxLengthValidator(length: number, errorMessage: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value) {
                return null;
            }
            const valid = control.value !== null && control.value.toString().length <= length;
            return valid ? null : { maxLength: { value: errorMessage } };
        };
    }

    static minLengthValidator(length: number, errorMessage: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value) {
                return null;
            }
            const valid = control.value !== null && control.value.toString().length >= length;
            return valid ? null : { maxLength: { value: errorMessage } };
        };
    }

    static dataPatternValidator(nameRe: RegExp, errorMessage: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value) {
                return null;
            }
            const valid = nameRe.test(control.value);
            return valid ? null : { invalidDataPattern: { value: errorMessage } };
        };
    }

    static dateFormatValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value) {
                return null;
            }
            const dateValid = (control.value instanceof Date) && (control.value.toString() != 'Invalid Date');
            return dateValid ? null : { invalidDate: { value: "Vnesite datum v formatu dd.mm.yyyy" } };
        };
    }

    static dateYearValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value) {
                return null;
            }
            const dateValid = control.value.getUTCFullYear() >= 1753;
            return dateValid ? null : { invalidDateYear: { value: "Letnica ne sme biti manjša od 1753 ali večja od 9999 " } };
        };
    }

    static dateRangeYearValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
          if(!control.value) {
              return null;
          }
          let dates = control.value as Array<Date>;
          const dateValid = dates[0].getFullYear() >= 1753 && dates[1].getFullYear() >= 1753 ;
          return dateValid ? null : { invalidDateYear: { value: "Letnica ne sme biti manjša od 1753 ali večja od 9999 " } };
      };
  }

    static dateGreaterThanTodayValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value) {
                return null;
            }
            const dateValid = control.value < new Date();
            return dateValid ? null : { dateGreaterThanToday: { value: "Datum ne sme biti večji od današnjega datuma " } };
        };
    }

    static dateRangeGreaterThanTodayValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
          if(!control.value) {
              return null;
          }
          let dates = control.value as Array<Date>;
          const dateValid = dates[0] < new Date() && dates[1] < new Date();
          return dateValid ? null : { dateGreaterThanToday: { value: "Datum ne sme biti večji od današnjega datuma " } };
      };
  }

    static dateRangeGreaterThanValidator(errorMessage: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
          if(!control.value || !control.parent) {
              return null;
          }
          let dates = control.value as Array<Date>;
          const dateValid = dates[1] >= dates[0];
          return dateValid ? null : { dateGreaterThan: { value: errorMessage } };
      };
  }

    static numberValidator(errorMessage: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if(!control.value) {
                return null;
            }
            const valid = !isNaN(+control.value)
            return valid ? null : { isNumber: { value: errorMessage } };
        };
    }

    static justOneValid = (validator: ValidatorFn, controls: string[] | null) => (
      group: UntypedFormGroup,
    ): ValidationErrors | null => {
      if (!controls) {
        controls = Object.keys(group.controls)
      }
      const numberOfValidInputs = group && group.controls && controls.filter(x => !validator(group.controls[x])).length;
      return numberOfValidInputs === 1 ? null : { justOneValid: true };
    };


    static selectedMoreThanAllowed = (validator: ValidatorFn, controls: string[] | null) => (
      group: UntypedFormGroup,
    ): ValidationErrors | null => {
      if (!controls) {
        controls = Object.keys(group.controls)
      }
      const numberOfValidInputs = group && group.controls && controls.filter(x => !validator(group.controls[x])).length;
      return numberOfValidInputs > 1 ? { selectedMoreThanAllowed: true } :  null ;
    };
}
