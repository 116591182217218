import { Router } from '@angular/router';
import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { Convert } from './convert';
import { ErrorToastrComponent } from '../modals/error-toastr/error-toastr.component';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  title = '';
  message = '';
  private lastInserted: number[] = [];
  options: GlobalConfig;
  constructor(private injector: Injector,
              private router: Router,
              private zone: NgZone,
              private toastr: ToastrService,
              private convert: Convert,
              private ngZone: NgZone
  ) {

    this.toastr.toastrConfig.autoDismiss = false;
    this.options = this.toastr.toastrConfig;
  }

  getErrorMessage() {
    const state = this.router.getCurrentNavigation()?.extras.state;
    if (typeof state === "undefined" || state === null) {
      return "Page not found.";
    }
    return this.getMessage(state['message']);
  }

  getMessage(error: any) {
    var res = "";
    try {
      res =
        typeof error === "object" ? error.messages : JSON.parse(error).messages;
    } catch (e) {
      res = error;
    }
    return res;
  }

  getErrorCode() {
    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation) {
      const state = this.router.getCurrentNavigation()?.extras.state;
      if (typeof state !== "undefined" && state !== null) {
        return state['errorCode'] || 404;
      }
    }
    return 404;
  }

  navigeteToErrorPage(errorMessage: string, code: number): void {
    this.zone.run(() => {
      this.router.navigate(["/error"], {
        state: { message: errorMessage, errorCode: code },
      });
    });
  }

  getClientErrorMessage(error: Error): string {
    return error.message ? error.message : error.toString();
  }

  getServerErrorMessage(errorResponse: HttpErrorResponse): string {
    if (!navigator.onLine) {
      return "No Internet Connection";
    }
    if(errorResponse.error && errorResponse.error.messages instanceof Array){
      return  errorResponse.error.messages.join(" - ")
    }
      return errorResponse.message.toString();
  }

  handleError(error: HttpErrorResponse) {
    let statusDetails: string = "";
    let message =  this.getServerErrorMessage(error);

    let status = 503;

    if (error.status === 401) {
      window.location.replace("/api/auth/login?returnUrl=" + encodeURIComponent(window.location.href));
    } else if (error.error instanceof ErrorEvent) {
      statusDetails = "Pri obdelavi zahteve je prišlo do napake. Opis napake: " + error.error.message;
    }else if (error.status >= status) {
      this.navigeteToErrorPage(statusDetails, status);
    } else {
      const errorList = new Map<number, string>([
        [400, ": Pri obdelavi zahteve je prišlo do napake. Prosimo poskusite znova čez nekaj trenutkov. V kolikor se napaka ponovi, prosimo kontaktirajte skrbnika informacijskega sistema SZZ."],
        [403, ": Nimate pravic za izvedbo operacije"],
        [404, ": Ni zapisov. Vpišite drugačne iskalne kriterije in poizkusite znova."],
        [500, ": Pri obdelavi zahteve je prišlo do napake. Prosimo poskusite znova čez nekaj trenutkov. V kolikor se napaka ponovi, prosimo kontaktirajte skrbnika informacijskega sistema SZZ."],
      ]);

      if (errorList != undefined) {
        statusDetails = error.status + (errorList.get(error.status) || '');
      }
      this.openErrorToast(message, statusDetails);
    }
  }

  hideToastr(){
    this.toastr.clear();
  }

  openErrorToast(messg: string, statusDetails: string) {
    const opt = cloneDeep(this.options);
    opt.toastComponent = ErrorToastrComponent;
    opt.enableHtml = true;
    opt.disableTimeOut = true;
    opt.positionClass = 'toast-bottom-right';
    opt.closeButton = true;
    opt.tapToDismiss = false;
    this.ngZone.run(() => {   const inserted = this.toastr.show(messg, statusDetails, opt); });
  }
}
