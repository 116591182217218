<div class="table-responsive" style="border-top-left-radius: 10px; border-top-right-radius: 10px;" >
  <table class="table table-bordered small mb-0" [ngStyle]="{'color': isUserUpdated ? 'grey' : ''}">
    <thead class="bg-primary text-white">
      <tr class="bg-primary text-center">
        <th scope="col" class="font-weight-normal">Serijska številka</th>
        <th scope="col" class="font-weight-normal">Izdajatelj - kratko ime</th>
        <th scope="col" class="font-weight-normal">Izdajatelj</th>
        <th scope="col" class="font-weight-normal">Komentar</th>
        <th scope="col" class="font-weight-normal">Onemogočeno</th>
        <th scope="col" class="font-weight-normal"> Spremenjeno </th>
        <th scope="col" class="font-weight-normal"></th>
        <th scope="col" class="font-weight-normal"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let digitalCertificate of this.digitalCertificates" class="text-center" [ngStyle]="{'color': digitalCertificate?.isDisabled ? 'grey' : ''}">
        <td> <span *ngIf="digitalCertificate?.isDisabled" [tooltip]="showDisabledCertificate" placement="bottom">
          <i class="fas fa-exclamation-circle status-refused mr-1"></i>
        </span>{{this.digitalCertificate?.serialNumber}}
        <ng-template #showDisabledCertificate> Onemogočeno </ng-template>
      </td>

        <td>{{this.digitalCertificate.friendlyName}}</td>
        <td>{{this.digitalCertificate?.publisher}}</td>
        <td>{{this.digitalCertificate?.comment}}</td>
        <td> {{this.digitalCertificate?.isDisabled ? "Da" : "Ne"}} </td>
        <td> {{this.digitalCertificate?.modifiedDate | date:'dd.MM.yyyy'}} </td>
        <td class="text-center" style="margin: 0px; padding: 0.25rem;">
          <span class="edit-btn">
            <i class="fas fa-pen-square" (click)="this.editDigitalCertificate(digitalCertificate)"></i>
          </span>
        </td>
        <td class="text-center"
            style="margin: 0px; padding: 0.25rem;">
          <span style="font-size: 22px; color: #77364a; cursor: pointer;">
            <i class="fas fa-trash-alt" (click)="this.deleteDigitalCertificate(digitalCertificate)"></i>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
