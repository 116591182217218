<div class="container-fluid pl-2 pr-2" *ngIf="isUserUpdated" style="position: absolute; align-content: center !important; border: 1px solid grey;" >
  <div class="card-load rounded border-0" style="background-color: transparent !important; z-index: 999; position: fixed; top: 50%; left: 25%; right: 35%; width: 50%;" [ngStyle]="{'color': !isUserUpdated ? 'white !important;' : ''}">
    <div class="row align-items-center " style="justify-content: center;">
      <div class="col text-center p-5" style="width: 15%; background: white; flex: 0 0 40%; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); border-radius: 8px">
        <h5 class="mb-1" style="color: black !important;">Prosimo počakajte...</h5>
        <img src="/assets/img/ajax-loader.gif" />
      </div>
    </div>
  </div>
</div>
