import { Injectable } from "@angular/core";
import { RoleExtended } from './model/role-extended';

@Injectable({ providedIn: "root" })
export class ArrayHelper {

  userTypes = [
    {
      key: 'INTERNAL_USER',
      value: 'Navadni uporabnik',
      isSelected: false,
    },
    {
      key: 'SYSTEM_USER',
      value: 'Uporabnik sistema',
      isSelected: false,
    },
  ];

  constructor() {}

  getUserTypes(){
    return this.userTypes;
  }

}
