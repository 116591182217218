import { Routes } from '@angular/router';
import { CodesComponent } from './pages/codes/codes/codes.component';
import { UserDetailsPageComponent } from './pages/user-details/user-details-page/user-details-page.component';
import { ErrorComponent } from './pages/error-page/error/error.component';


export const ROUTES: Routes = [
  {
    path: '',
    component: CodesComponent,
    data: {
      title: 'codes',
      breadcrumb: 'dashboard',
    },
  },
  {
    path: 'user-details/:id',
    component: UserDetailsPageComponent,
    loadChildren: () => import('./pages/user-details/user-details-page.module').then(m => m.UserDetailsModule),
    data: {
      title: 'user-details',
      breadcrumb: 'dashboard',
    },
  },
  {
    path: 'add-user',
    component: UserDetailsPageComponent,
    loadChildren: () => import('./pages/user-details/user-details-page.module').then(m => m.UserDetailsModule),
    data: {
      title: 'add-user',
      breadcrumb: 'dashboard',
    },
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    data: { title: 'dashboard', breadcrumb: 'dashboard' },
  },
  {
    path: "error",
    component: ErrorComponent,
    data: {
      title: "Error Page",
      breadcrumb: "Error Page",
    },
  },
];
