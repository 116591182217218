<div class="container-fluid pl-2 pr-2" *ngIf="searchInProgress">
  <div class="card rounded border-0">
    <div class="row align-items-center ">
      <div class="col text-center p-5">
        <h5 class="mb-1">Prosimo počakajte, iskanje v teku...</h5>
        <img src="/assets/img/ajax-loader.gif" />
      </div>
    </div>
  </div>
</div>
