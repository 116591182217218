import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'szz-loader-search-in-progress',
  templateUrl: './loader-search-in-progress.component.html',
  styleUrls: ['./loader-search-in-progress.component.scss']
})
export class LoaderSearchInProgressComponent implements OnInit {

  @Input() searchInProgress: boolean | null = false;

  constructor() { }

  ngOnInit(): void {}

}
