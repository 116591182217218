import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DigitalCertificateComponent } from '../../modals/digital-certificate/digital-certificate.component';
import { DeleteComponent } from '../../modals/delete/delete.component';
import { DigitalCertificate } from '../../data/api/models/digital-certificate';
import { Convert } from '../../common/convert';
import { UserCommonService } from '../../common/user-common.service';

@Component({
  selector: 'szz-digital-certificate-table',
  templateUrl: './digital-certificate-table.component.html',
  styleUrls: ['./digital-certificate-table.component.scss'],
})
export class DigitalCertificateTableComponent implements OnInit, OnChanges  {
  modalRef: BsModalRef;

  @Input() userId = '';
  @Input() digitalCertificates: DigitalCertificate[] = [];
  @Output() update: EventEmitter<{ isUpdated: boolean }> = new EventEmitter();
  @Output() delete: EventEmitter<{ isDeleted: boolean }> = new EventEmitter();
  digitalCertificate: DigitalCertificate = {};

  isUserUpdated: boolean = false;

  constructor(
    private modalService: BsModalService,
    public convert: Convert,
    private userCommonService: UserCommonService
  ) {
    this.modalRef = new BsModalRef();
  }

  ngOnInit(): void {
    this.isUserUpdated = this.userCommonService.getIsUserUpdated();
  }

  ngDoCheck(){
    this.isUserUpdated = this.userCommonService.getIsUserUpdated();
  }

  ngOnChanges(): void {}

  editDigitalCertificate(digitalCertificate: DigitalCertificate) {
    this.modalRef = this.modalService.show(DigitalCertificateComponent, {
      class: 'modal-md',
      initialState: {
        data: {
          userId: this.userId,
          digitalCertificate: digitalCertificate ? digitalCertificate : {},
          status: 'update',
        },
      },
    });

    let subscriptionActivate = this.modalService.onHide.subscribe((x) => {
      if (x == 'SAVE_RESULT') {
        this.digitalCertificate = this.userCommonService.getDigCertificate();
        if (this.digitalCertificate && this.digitalCertificates !== null && this.digitalCertificates !== undefined) {
          let updateItem: DigitalCertificate | any = this.digitalCertificates.find((item) => item.digitalCertificateId === this.digitalCertificate.digitalCertificateId);
          let index = this.digitalCertificates.indexOf(updateItem);
          this.digitalCertificates[index] = this.digitalCertificate;
          this.userCommonService.setDigCertificates(this.digitalCertificates);
          this.digitalCertificates = this.userCommonService.getDigCertificates();
          this.userCommonService.setDigCerStatus('update-digital-certificate');
        }
        this.update.emit({ isUpdated: true });
        subscriptionActivate.unsubscribe();
      } else {
        subscriptionActivate.unsubscribe();
      }
    });
  }

  deleteDigitalCertificate(digitalCertificate: DigitalCertificate) {
    this.modalRef = this.modalService.show(DeleteComponent, {
      class: 'modal-md',
      initialState: {
        data: {
          okLabel: 'Potrdi',
          closeLabel: 'Prekini',
          message: 'Ali želite izbrisati digitalno potrdilo?',
        },
      },
    });

    let subscriptionActivate = this.modalService.onHide.subscribe((x) => {
      if (x == 'OK') {
        this.digitalCertificates = this.userCommonService.getDigCertificates();
        this.digitalCertificates.splice(this.digitalCertificates.indexOf(digitalCertificate),1);
        this.userCommonService.setDigCertificates(this.digitalCertificates);
        this.userCommonService.setDigCerStatus('delete-digital-certificate');
        this.delete.emit({ isDeleted: true });
        subscriptionActivate.unsubscribe();
      } else {
        subscriptionActivate.unsubscribe();
      }
    });
  }
}
