
import { Injectable } from '@angular/core';
import { User } from '../data/api/models/user';
import { DigitalCertificate } from '../data/api/models/digital-certificate';

@Injectable({
    providedIn: 'root'
})
export class UserCommonService {

  users: Array<User> = [];
  user = {};
  aplicationsFilter: any[] = [];
  status: string = "";
  digitalCertificates: DigitalCertificate[] = [];
  digitalCertificate: any = {};
  updated: boolean = false;

  pager: any;
  pageSize: number = 50;
  currentPage: number = 1;

  constructor(){}

  setUsers(users: Array<User> ){
    this.users = users;
  }

  getUsers(){
    return this.users;
  }

  setUserReq(userReq: any ){
    this.user = userReq;
  }

  getUserReq(){
    return this.user;
  }

  setPageSize(pageSize: number){
    this.pageSize = pageSize;
  }

  getPageSize(){
    return this.pageSize;
  }

  setCurrentPage(currentPage: number){
    this.currentPage = currentPage;
  }

  getCurrentPage(){
    return this.currentPage;
  }

  setApplicationsFilter(aplicationsFilter: any[] ){
    this.aplicationsFilter = aplicationsFilter;
  }

  getApplicationsFilter(){
    return this.aplicationsFilter;
  }

  setDigCerStatus(status: string){
    this.status = status;
  }

  getDigCerStatus(){
    return this.status;
  }

  setDigCertificate(digitalCertificate: any){
    this.digitalCertificate = digitalCertificate;
  }

  getDigCertificate(){
    return this.digitalCertificate;
  }

  setDigCertificates(digitalCertificates: DigitalCertificate[]){
    this.digitalCertificates = digitalCertificates;
  }

  getDigCertificates(){
    return this.digitalCertificates;
  }

  setIsUserUpdated(updated: boolean){
    this.updated = updated;
  }

  getIsUserUpdated(){
    return this.updated;
  }
}
