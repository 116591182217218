import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProfileViewModel } from './data/api/models/profile-view-model';
import { BackButtonStateService } from './common/back-button-state.service';

@Component({
  selector: 'szz-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'kc-admin-app';

  idleState = "Not started.";
  timedOut = false;
  lastPing: Date | undefined;
  isAuthenticated: boolean = false;
  profileModel: ProfileViewModel = {};
  private logoutRouterLink: string = "/api/auth/logout?returnUrl=";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backButtonStateService: BackButtonStateService,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["showBack"]) {
              return child.snapshot.data["showBack"];
            } else {
              return null;
            }
          }
          return null;
        }),
        tap(x => { this.backButtonStateService.updateButtonState(x || false); })
      ).subscribe();
  }
}
