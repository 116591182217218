import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModule } from '../common/bootstrap.module';
import { UserOverviewComponent } from './user-overview/user-overview.component';
import { PaginationComponent } from './pagination/pagination.component';
import { UserTableComponent } from './user-table/user-table.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { DigitalCertificateTableComponent } from './digital-certificate-table/digital-certificate-table.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderSearchInProgressComponent } from './loader-search-in-progress/loader-search-in-progress.component';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { ModalsModule } from '../modals/modals.module';

@NgModule({
  declarations: [
    UserOverviewComponent,
    PaginationComponent,
    UserTableComponent,
    UserEditComponent,
    DigitalCertificateTableComponent,
    LoaderComponent,
    LoaderSearchInProgressComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    CollapseModule,
    BootstrapModule,
    DragDropModule,
    RouterModule,
    ReactiveFormsModule,
    MatListModule,
    MatInputModule,
    FormsModule,
    TabsModule,
    TabsModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  exports: [
    DragDropModule,
    TabsModule,
    UserTableComponent,
    PaginationComponent,
    UserTableComponent,
    UserEditComponent,
    LoaderComponent,
    LoaderSearchInProgressComponent
  ],
})
export class ComponentsModule {}
