
import { Injectable } from "@angular/core";
import { UntypedFormGroup, ValidationErrors, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
@Injectable({
  providedIn: "root",
})
export class ValidatorHelper {

  constructor() {}

  getFormValidationErrors(form: UntypedFormGroup | any, key: string) {
    const result: any = [];
    const controlErrors: ValidationErrors | null = form.get(key).errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach((keyError) => {
        result.push({
          control: key,
          error: keyError,
          value: controlErrors[keyError].value,
        });
      });
    }
    return result;
  }

  hasError(form: UntypedFormGroup, key: string) {
    return form.controls[key].errors;
  }

  hasErrorAndTouched(form: UntypedFormGroup, key: string) {
    const res = form.controls[key].errors && form.controls[key].touched;
    return res;
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

}
