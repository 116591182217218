import { Injectable } from "@angular/core";
import * as DateFns from 'date-fns';
@Injectable({
  providedIn: 'root',
})
export class Convert {
  constructor() {}

  convertIsUserActive(isActive?: boolean | null | undefined) {
    if (isActive) {
      return 'Aktiven';
    }
    return 'Neaktiven';
  }

  convertUserActiveIcon(value?: boolean | null | undefined) {
    switch (value) {
      case true:
        return 'fa-check-circle status-closed';
      case false:
        return 'fa-exclamation-circle status-refused';
      default:
        return 'Ni podatka';
    }
  }

  convertInsuranceStatusCode(code?: string) {
    switch (code) {
      case 'AA':
        return 'veljavna';
      case 'XP':
        return 'neveljavna prekinjena';
      case 'XN':
        return 'neveljavna potekla';
      default:
        return 'Ni podatka';
    }
  }

  convertStatusDescription(code?: 'registriran' | 'črtano' | 'evidentirano' | 'odjavljeno' | 'protokolarno' | 'razgrajeno' | 'ukradeno' | 'v postopku' | 'vpisano') {
    switch (code) {
      case 'registriran':
        return 'fa-check-circle';
      case 'črtano':
        return 'fa-exclamation-circle';
      case 'evidentirano':
        return 'fa-check-circle';
      case 'odjavljeno':
        return 'fa-dot-circle';
      case 'razgrajeno':
        return 'fa-exclamation-circle';
      case 'ukradeno':
        return 'fa-exclamation-circle';
      case 'v postopku':
        return 'fa-dot-circle';
      case 'vpisano':
        return 'fa-dot-circle';
      default:
        return '';
    }
  }

  convertStatusDescriptionStyle(code?: 'registriran' | 'črtano' | 'evidentirano' | 'odjavljeno' | 'protokolarno' | 'razgrajeno' | 'ukradeno' | 'v postopku' | 'vpisano') {
    switch (code) {
      case 'registriran':
        return 'status-closed';
      case 'črtano':
        return 'status-closed';
      case 'evidentirano':
        return 'status-closed';
      case 'odjavljeno':
        return 'status-open';
      case 'razgrajeno':
        return 'status-open';
      case 'ukradeno':
        return 'status-refused';
      case 'v postopku':
        return 'status-closed';
      case 'vpisano':
        return 'status-closed';
      default:
        return '';
    }
  }

  convertStatusIcon(status?: 'AA' | 'XP' | 'O' | 'R' | 'Z') {
    switch (status) {
      case 'XP':
        return 'fa-exclamation-circle';
      case 'O':
        return 'fa-exclamation-circle';
      case 'R':
        return 'fa-dot-circle';
      case 'AA':
        return 'fa-check-circle ';
      case 'Z':
        return 'fa-check-circle';
      default:
        return '';
    }
  }

  convertCompanyCode(code?: 'SLO-01' | 'SLO-16' | 'SLO-03' | 'SLO-02' | 'SLO-10' | 'SLO-11' | 'SLO-13' | 'SLO-14') {
    switch (code) {
      case 'SLO-01':
        return 'ADR';
      case 'SLO-16':
        return 'TRI';
      case 'SLO-03':
        return 'SAV';
      case 'SLO-02':
        return 'GSZ';
      case 'SLO-10':
        return 'GRW';
      case 'SLO-11':
        return 'ERG';
      case 'SLO-13':
        return 'CZL';
      case 'SLO-14':
        return 'ALZ';
      default:
        return 'Ni podatka';
    }
  }

  isNullOrEmpty(str: string) {
    return !str || str == null || str.length === 0;
  }

  convertDate(date: any) {
    return !this.isNullOrEmpty(date)
      ? DateFns.startOfDay(date).toISOString()
      : null;
  }

  stringToDate(str: string) {
    var splitted = str.split('.', 3);
    return new Date(splitted[2] + '-' + splitted[1] + '-' + splitted[0]);
  }

  convertVehicleTypeLabel(vehicleType: 'Car' | 'Truck' | 'Bus' | 'Tractor' | 'SpecialMotorVehicle' | 'MotorBike' | 'Trailer' | 'Machine') {
    switch (vehicleType) {
      case 'Car':
        return 'Osebno vozilo';
      case 'Truck':
        return 'Tovorno vozilo';
      case 'Bus':
        return 'Avtobus';
      case 'Tractor':
        return 'Traktor';
      case 'SpecialMotorVehicle':
        return 'Specialno motorno vozilo';
      case 'MotorBike':
        return 'Motor';
      case 'Trailer':
        return 'Priklopnik';
      case 'Machine':
        return 'Stroj';
      default:
        return 'Ni podatka';
    }
  }

  convertStatusStyle(status?: 'V' | 'O' | 'R' | 'Z' | 'A' | 'Z') {
    switch (status) {
      case 'R':
        return 'status-refused';
      case 'O':
        return 'status-refused';
      case 'V':
        return 'status-open';
      case 'Z':
        return 'status-closed';
      case 'A':
        return 'claim-status-refused';
      default:
        return '';
    }
  }

  convertClaimFileStatusLabel(status?: 'A' | 'O' | 'R' | 'Z') {
    switch (status) {
      case 'A':
        return 'Ad acta';
      case 'O':
        return 'Odklonjena';
      case 'R':
        return 'Registrirana';
      case 'Z':
        return 'Zaključena';
      default:
        return 'Ni podatka';
    }
  }

  convertClaimRoleLabel(claimRole?: 'Causer' | 'Damaged') {
    switch (claimRole) {
      case 'Causer':
        return 'Povzročitelj';
      case 'Damaged':
        return 'Oškodovanec';
      default:
        return '-';
    }
  }

  manipulateWithText(text: string) {
    if (text && text.length > 60) {
      return text.substring(0, 60) + '...';
    } else {
      return text;
    }
  }

  manipulateWithErrorText(text: string) {
    if (text && text.length > 110) {
      return text.substring(0, 110) + '...';
    } else {
      return text;
    }
  }
}
