<div class="d-flex justify-content-between">
    <div class="d-flex align-items-center pb-3 pr-3">
      <span class="pr-2">Prikaži</span>
      <span style="width: 100px !important;">
        <mat-select class="form-control" [formControl]="selectedFormItem" >
          <mat-option *ngFor="let page of this.pages"  [value]="page?.value" (click)="setPage(1)"> {{page?.key}}</mat-option>
        </mat-select>
      </span>
      <span class="pl-2">vrstic</span>
    </div>

    <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
      <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
          <a (click)="setPage(1)" class="page-link"><i class="fas fa-angle-double-left"></i></a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
          <a (click)="setPage(pager.currentPage - 1)" class="page-link"><i class="fas fa-angle-left"></i></a>
      </li>
      <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">
          <a (click)="setPage(page)" class="page-link">{{page}}</a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
          <a (click)="setPage(pager.currentPage + 1)" class="page-link"><i class="fas fa-angle-right"></i></a>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
          <a (click)="setPage(pager.totalPages)" class="page-link"><i class="fas fa-angle-double-right"></i></a>
      </li>
    </ul>
  </div>
