import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BootstrapModule } from './common/bootstrap.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { ServiceLocator } from "./common/service.locator";
import localeSL from "@angular/common/locales/sl";
import localeSlExtra from "@angular/common/locales/extra/sl";
import { ComponentsModule } from './components/components.module';
import { CodesModule } from './pages/codes/codes.module';
import { ApiModule } from './data/api/api.module';
import { UserDetailsModule } from './pages/user-details/user-details-page.module';
import { ModalsModule } from './modals/modals.module';
import { HttpConfigInterceptor } from './common/http-authorization.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from "ngx-clipboard";
import { ErrorPageModule } from './pages/error-page/error-page.module';
import { GlobalErrorHandler } from './global-error-handler';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

registerLocaleData(localeSL, "sl", localeSlExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    BootstrapModule,
    LayoutModule,
    BrowserAnimationsModule,
    ApiModule.forRoot({ rootUrl: "/api" }),
    RouterModule.forRoot(ROUTES, { onSameUrlNavigation: "reload" }),
    ComponentsModule,
    CodesModule,
    UserDetailsModule,
    ModalsModule,
    ErrorPageModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    ClipboardModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
