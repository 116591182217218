import { Injectable, Injector, NgZone } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BackButtonStateService {
  private showButtonData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showButton: Observable<boolean>;

  constructor() {
    this.showButton = this.showButtonData.asObservable();
  }

  updateButtonState(newState: boolean) {
    this.showButtonData.next(newState);
  }
}
